<template>
  <div class="container">
    <div class="columns">
      <div class="column is-12">
        <h4 class="page-header">
          <b-icon icon="chart-bar"></b-icon>
          <span>{{ $ml.get('titles_statistic') }}</span>
        </h4>
      </div>
    </div>
    <div class="columns is-centered">
      <div class="column is-8">
        <b-tabs type="is-boxed">
          <b-tab-item value="stat" :label="$ml.get('statistic_user_common')" icon="chart-bar">
            <div class="columns is-centered is-variable is-4">
              <div class="column is-3">
                <div class="dash-plate">
                  <div class="header is-primary">{{ $ml.get('dashboard_bets_count') }}</div>
                  <div class="body">
                    <div class="side-icon text-primary">
                      <b-icon icon="star"></b-icon>
                    </div>
                    <div class="side-info">
                      <span>{{ new Intl.NumberFormat().format(total.bets_count) }}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="column is-3">
                <div class="dash-plate">
                  <div class="header is-info">{{ $ml.get('dashboard_turnover') }}</div>
                  <div class="body">
                    <div class="side-icon has-text-info">
                      <b-icon icon="reload"></b-icon>
                    </div>
                    <div class="side-info">
                      <span><Money currency="EUR" :subunits=true v-bind:amount="total.turnover"/></span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="column is-3">
                <div class="dash-plate">
                  <div class="header is-success">{{ $ml.get('dashboard_profit') }}</div>
                  <div class="body">
                    <div class="side-icon has-text-success">
                      <b-icon icon="currency-usd"></b-icon>
                    </div>
                    <div class="side-info">
                      <span><Money :subunits=true currency="EUR" v-bind:amount="total.profit"/></span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="column is-3">
                <div class="dash-plate">
                  <div class="header is-warning">ROI</div>
                  <div class="body">
                    <div class="side-icon has-text-warning">
                      <b-icon icon="finance"></b-icon>
                    </div>
                    <div class="side-info">
                      <span>{{ total.roi }} %</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <form @submit.prevent="makeFilter()" novalidate>
              <div class="columns">
                <div class="column is-5">
                  <b-field>
                    <b-datepicker
                        v-model="filter.from"
                        :show-week-number="false"
                        :locale="$ml.current === 'ru' ? 'ru-RU' : 'en-GB'"
                        icon="calendar-today"
                        editable
                        trap-focus>
                    </b-datepicker>
                  </b-field>
                </div>
                <div class="column is-5">
                  <b-field>
                    <b-datepicker
                        v-model="filter.to"
                        :show-week-number="false"
                        :locale="$ml.current === 'ru' ? 'ru-RU' : 'en-GB'"
                        icon="calendar-today"
                        editable
                        trap-focus>
                    </b-datepicker>
                  </b-field>
                </div>
                <div class="column is-2">
                  <b-button type="is-info" native-type="submit">{{ $ml.get('common_show') }}</b-button>
                </div>
              </div>
            </form>
            <div class="columns is-centered">
              <div class="column is-12">
                <b-table
                    :data="items"
                    :paginated="true"
                    :bordered="true"
                    :striped="true"
                    :loading="loading"
                    :per-page="perPage">
                  <b-table-column field="date" :label="$ml.get('statistic_date')" sortable v-slot="props">
                    {{ props.row.date }}
                  </b-table-column>
                  <b-table-column field="bets" width="60" :label="$ml.get('statistic_bets_count')" sortable v-slot="props">
                    {{ new Intl.NumberFormat().format(props.row.bets) }}
                  </b-table-column>
                  <b-table-column field="turnover" width="80" :label="$ml.get('statistic_turnover')" sortable v-slot="props">
                    <Money currency="EUR" v-bind:amount="props.row.turnover"/>
                  </b-table-column>
                  <b-table-column field="profit" width="80" :label="$ml.get('statistic_profit')" sortable v-slot="props">
                    <span :class="props.row.profit >= 0 ? 'text-success' : 'text-danger'">
                      <Money currency="EUR" v-bind:amount="props.row.profit"/>
                    </span>
                  </b-table-column>
                  <b-table-column field="roi" width="80" label="ROI" sortable v-slot="props">
                    <span :class="props.row.roi >= 0 ? 'text-success' : 'text-danger'">
                      {{ props.row.roi }} %
                    </span>
                  </b-table-column>
                  <b-table-column field="referrals" width="80" :label="$ml.get('statistic_referral_profit')" sortable v-slot="props">
                    <span v-if="props.row.referrals > 0" class="text-success">
                      <Money currency="EUR" v-bind:amount="props.row.referrals"/>
                    </span>
                    <span v-if="props.row.referrals <= 0" class="text-success">-</span>
                  </b-table-column>
                </b-table>
              </div>
            </div>
          </b-tab-item>
          <b-tab-item value="payments" :label="$ml.get('statistic_payments')" icon="cash">
            <i v-if="payments.length === 0">{{ $ml.get('statistic_no_payments') }}</i>
            <div class="columns is-centered" v-if="payments.length > 0">
              <div class="column is-12">
                <b-table
                    :data="payments"
                    :paginated="true"
                    :bordered="true"
                    :striped="true"
                    :loading="loading"
                    :per-page="perPage">
                  <b-table-column field="amount" :label="$ml.get('statistic_amount')" class="rightal" sortable v-slot="props">
                    <Money currency="EUR" v-bind:amount="props.row.amount"/>
                  </b-table-column>
                  <b-table-column field="created_at" :label="$ml.get('statistic_date')" class="leftal" sortable v-slot="props">
                    {{ props.row.created_at_hr }}
                  </b-table-column>
                </b-table>
              </div>
            </div>
          </b-tab-item>
          <b-tab-item value="ref" :label="$ml.get('statistic_referrals')" icon="account">
            <i v-if="!this.$store.getters['auth/user'].ref_enabled">{{ $ml.get('statistic_referral_disabled') }}</i>
            <section v-if="this.$store.getters['auth/user'].ref_enabled">
              <div class="columns is-centered">
                <div class="column is-7">
                  <div class="leftal">
                    {{ $ml.get('statistic_referral_link') }}: <i class="text-success" style="text-decoration: underline">https://cloud.betexy.com/signup?rc={{
                      this.$store.getters['auth/user'].ref_code
                    }}</i>
                  </div>
                  <div class="leftal">
                    {{ $ml.get('statistic_referral_percent') }}: <i style="text-decoration: underline">{{
                      this.$store.getters['auth/user'].ref_percent
                    }}% {{ $ml.get('statistic_referral_turnover') }}</i>
                  </div>
                  <div class="leftal" v-if="this.$store.getters['auth/user'].max_refs">
                    {{ $ml.get('statistic_referral_limit') }}: <i style="text-decoration: underline">{{
                      this.$store.getters['auth/user'].max_refs
                    }}</i>
                  </div>
                </div>
                <div class="column is-3">
                  {{ $ml.get('statistic_referral_profit_sum') }}:
                  <Money :subunits=true currency="EUR" v-bind:amount="total.ref_profit"/>
                </div>
                <div class="column is-2">
                  {{ $ml.get('statistic_referral_payouts') }}:
                  <Money :subunits=true currency="EUR" v-bind:amount="total.payouts"/>
                </div>
              </div>
              <div class="columns is-centered" v-if="referral.length > 0">
                <div class="column is-12">
                  <b-table
                      :data="referral"
                      :paginated="true"
                      :bordered="true"
                      :striped="true"
                      :loading="loading"
                      :per-page="perPage">
                    <b-table-column field="referral" :label="$ml.get('statistic_referral')" searchable v-slot="props">
                      {{ props.row.referral }}
                    </b-table-column>
                    <b-table-column field="registered" :label="$ml.get('statistic_referral_registered')" sortable v-slot="props">
                      {{ new Date(props.row.registered * 1000).toLocaleString() }}
                    </b-table-column>
                    <b-table-column field="amount" width="100" :label="$ml.get('statistic_profit')" sortable v-slot="props">
                      <Money currency="EUR" v-bind:amount="props.row.amount"/>
                    </b-table-column>
                  </b-table>
                </div>
              </div>
            </section>
          </b-tab-item>
        </b-tabs>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import Money from "@/components/Money";

export default {
  name: "Room",
  data() {
    return {
      perPage: 14,
      currentPage: 1,
      loading: false,
      rawItems: [],
      items: [],
      referral: [],
      payments: [],
      total: {
        turnover: 0,
        profit: 0,
        bets_count: 0,
        roi: 0,
        ref_profit: 0,
        payouts: 0,
      },
      filter: {
        from: null,
        to: null,
      },
    }
  },
  components: {Money},
  computed: {},
  methods: {
    retrieve() {
      this.loading = true
      Vue.prototype.$http.get(process.env.VUE_APP_API_HOST + `statistic/index`).then(resp => {
        this.total = resp.data.result.total
        this.items = resp.data.result.items
        this.rawItems = resp.data.result.items
        this.referral = resp.data.result.referral
        this.payments = resp.data.result.payments
      }).catch(err => {
        this.flashMessage.error({
          title: this.$ml.get('common_request_failed'),
          message: this.$ml.get('common_request_failed_message'),
        })
      }).finally(() => {
        this.loading = false
      })
    },
    makeFilter() {
      let items = []
      if (this.filter.from || this.filter.to) {
        this.rawItems.forEach((item, i, arr) => {
          if (this.filter.from) {
            if (new Date(item.date).getTime() < new Date(this.filter.from).getTime()) {
              return
            }
          }
          if (this.filter.to) {
            if (new Date(item.date).getTime() > new Date(this.filter.to).getTime()) {
              return
            }
          }
          items.push(item)
        })
        this.items = items
      } else {
        this.items = this.rawItems
      }
      this.total.turnover = 0
      this.total.profit = 0
      this.total.bets_count = 0
      this.total.roi = 0
      this.items.forEach((item, i) => {
        this.total.bets_count += item.bets
        this.total.profit += item.profit
        this.total.turnover += item.turnover
      })
      if (this.total.turnover) {
        this.total.roi = parseFloat(this.total.profit * 100 / this.total.turnover).toFixed(2)
      }
    }
  },
  mounted() {
    this.retrieve()
  },
  metaInfo() {
    return {
      title: this.$ml.get('titles_statistic')
    }
  }
}
</script>

<style scoped>
</style>